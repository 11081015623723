<template>
  <div id="schools">
    <div class="pages-header">
      <el-image :src="require('../../assets/actbg@3x.png')" fit="cover">
      </el-image>
    </div>

    <div class="schools-content">
      <div class="content-title">
        <p><i class="el-icon-s-home"></i> 学校展示</p>
      </div>
      <div class="content-warp">
        <div class="card" v-for="(item, index) in schools" :key="index">
          <el-image :src="item.img" fit="cover" :preview-src-list="srcList" @click="previewImage"></el-image>
          <div class="card-text">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div style="clear: both"></div>
  </div>
</template>

<script>
export default {
  name: "schools",
  components: {},
  created() {
    // this.getSchools();
  },
  mounted() {
    let that = this;

    const files = require.context("@/assets/schoolpics/", false, /.jpg$/);

    // 遍历输出匹配结果
    files.keys().forEach((path) => {
      // var content = files(path) // 获取文件 export default 内容
      that.schools.push({
        name: path.split("./")[1].split(".")[0],
        img: files(path),
      });
    });
  },
  data() {
    return {
        srcList:[],
      schools: [],
    };
  },
  methods: { 
      previewImage(data){
          this.srcList=[];
          this.srcList.push(data.srcElement.currentSrc);
      }
  },
};
</script>

<style scoped lang="less">
#schools {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
  line-height: 36px;

  .pages-header {
    width: 100%;

    .el-image {
      display: block;
      width: 100%;
      /*height: 200px;*/
    }
  }

  .schools-content {
    width: 100%;

    .content-title {
      width: 100%;
      height: 87px;
      padding: 0 100px 0 100px;
      border-bottom: 1px solid #e5e5e5;
      display: flex;
      align-items: center;
      opacity: 0.8;
      font-weight: bold;

      p {
        margin: 0;
      }
    }

    .content-warp {
      padding: 1vw 5vw 5vw 5vw;
      display: flex;
      flex-wrap: wrap;
      /*justify-content: flex-start;*/
      .card {
        width: 280px;
        background: #e9e9e9;
        border: 0;
        margin: 1vw;

        .el-image {
          width: 100%;
          height: 200px;
        }

        .card-text {
          padding: 10px 20px 15px 20px;
          text-align: center;
          font-size: 16px;
          line-height: 1;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }

  @media (max-width: 900px) {
    .content-title {
      padding-left: 50px !important;
      height: 15vw !important;
    }
    .content-warp {
      justify-content: space-around;
    }
  }
}
</style>

